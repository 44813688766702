
import { defineComponent, reactive, onMounted, onBeforeUnmount, toRefs } from 'vue'
import useVuelidate from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import ModalLink from '@/components/CMSModalLink.vue'
import InfoIcon from '@/components/vectors/Info.vue'
import { createAccount } from '@/expressway-api/auth.api'
import useSnackbar from '@/composables/useSnackbar'
import Spinner from '@/components/Spinner.vue'
import { useRouter } from 'vue-router'
import Input from '@/components/Input.vue'
import Checkbox from '@/components/Checkbox.vue'
import { sendExponeaCustomerRegistered } from '@/helpers/exponeaHelper'
import { loadRecaptcha, unloadRecaptcha } from '@/utils/loadRecaptcha'

declare const window: any

export default defineComponent({
  components: {
    Spinner,
    ModalLink,
    InfoIcon,
    'v-input': Input,
    'v-checkbox': Checkbox
  },
  setup () {
    const router = useRouter()
    const { setSnackbar } = useSnackbar()
    const state = reactive({
      email: '',
      marketing: false,
      loadingCreateAccount: false
    })

    const rules = {
      email: {
        required,
        email: helpers.withMessage('Please enter a valid Email Address', email)
      },
      marketing: {}
    }

    onMounted(() => {
      loadRecaptcha(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY)
        .catch(() => {
          setSnackbar('Error loading reCAPTCHA. Please try again later.')
        })
    })

    onBeforeUnmount(() => {
      unloadRecaptcha()
    })

    const createAccountCall = async () => {
      state.loadingCreateAccount = true
      if (!window.grecaptcha) {
        setSnackbar('Error loading reCAPTCHA. Please try again later.')
        return
      }
      const grecaptcha = window.grecaptcha || {}
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'signup' })
          .then((token: any) => {
            createAccount(state.email, state.marketing, token)
              .then(() => {
                sendExponeaCustomerRegistered(state.email)
                router.push({ name: 'CreateAccountSuccess' })
              })
              .catch(e => {
                state.loadingCreateAccount = false
                if (e.response?.data?.Message &&
                  Array.isArray(e.response.data.Message) &&
                  e.response.data.Message.length > 0
                ) {
                  if (Object.prototype.hasOwnProperty.call(e.response.data.Message[0], 'Error')) {
                    // eslint-disable-next-line max-len
                    const message = 'Sorry, we couldn’t create your account. If you already have one, try <a href="/forgot-password">resetting your password</a>.'
                    setSnackbar(message)
                    return
                  }
                }
                setSnackbar(e.message)
              })
          })
      })
    }

    return {
      v$: useVuelidate(rules, state),
      createAccountCall,
      ...toRefs(state)
    }
  }
})
